<template>
  <div class="transaction-list-item">
    <div class="box">
      <div class="is-flex is-justify-content-space-between">
        <div>
          <small class="transaction-date has-text-grey is-small">
            <i class="fas fa-calendar-alt"></i> {{ transaction.date|moment('DD.MM.YYYY') }}
          </small>
        </div>
        <div v-if="transaction.user"
             class="user tag is-primary has-text-weight-bold is-rounded"
             :style="{'background-color': getUserColor(transaction.user).standard, 'border-color': getUserColor(transaction.user).lighter}"
        >
          {{ getUserName(transaction.user) }}
        </div>
      </div>

      <div class="transaction-content">
        <p class="title">
          {{ $n(transaction.amount, 'currency', 'de-AT') }}
        </p>

        <p class="subtitle">{{ transaction.purpose }}</p>
      </div>

      <div class="tags">
                  <span class="tag is-info is-light" v-if="transaction.isHousehold">
                    <span class="icon">
                      <i class="fas fa-home"></i>
                    </span>
                  </span>
      <span class="tag" v-for="categoryId in transaction.categories" :key="categoryId">
                    {{ getCategoryName(categoryId) }}
                  </span>
      </div>
    </div>

    <div class="box context-menu">
      <router-link :to="`/${accountBook.slug}/transactions/edit/${transaction._id}`" class="button is-info">
                <span class="icon">
                  <i class="fas fa-pen"></i>
                </span>
      </router-link>
      <button class="button is-danger" @click="remove(transaction._id)">
                <span class="icon">
                  <i class="fas fa-trash"></i>
                </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { COLORS } from '../../constants'

export default {
  name: 'transaction-list-item',
  computed: {
    ...mapState({
      users: state => state.users.items,
      categories: state => state.categories.items,
      accountBook: state => state.accountBooks.current
    }),
    ...mapGetters([
      'categoryOptions'
    ])
  },
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  methods: {
    getUser (userId) {
      return this.users.find(user => user._id === userId)
    },
    getUserName (userId) {
      const user = this.getUser(userId)

      if (!user) {
        return null
      }

      return user.name.match(/\b(\w)/g).join('')
    },
    getUserColor (userId) {
      const user = this.getUser(userId)

      if (!user) {
        return COLORS.standard
      }

      if (COLORS[user.color]) {
        return COLORS[user.color]
      } else {
        return COLORS.standard
      }
    },
    getCategoryName (categoryId) {
      const category = this.categories.find(category => category._id === categoryId)

      if (!category) {
        return null
      }

      return category.name
    },
    toggleContextMenu (transactionId) {
      return dir => {
        if (dir === 'left') {
          this.$refs[`transaction_box_${transactionId}`][0].classList.add('show-context-menu')
        }

        if (dir === 'right') {
          this.$refs[`transaction_box_${transactionId}`][0].classList.remove('show-context-menu')
        }
      }
    },
    remove (_id) {
      if (confirm('Are you sure, you want to delete this transaction?')) {
        this.$store.dispatch('transactions/delete', _id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  .transaction {
    transition: transform 0.2s ease;

    .box {
      padding: 0.5rem;
    }

    .transaction-content {
      padding: 0.5rem;
    }

    p.subtitle {
      margin-bottom: 0.25em;
    }

    .user {
      width: 1.75rem;
      height: 1.75rem;
      border: 0.1rem solid;
    }

    &.show-context-menu {
      transform: translateX(-6rem);

      .context-menu {
        right: -6rem;
      }
    }

    .context-menu {
      transition: right 0.2s ease;
      display: flex;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      justify-content: flex-end;
      padding: 0;

      .button {
        height: 100%;
        border-radius: 0;
        width: 3rem;

        &:last-child {
          border-radius: 0 6px 6px 0;
        }
      }
    }
  }
</style>

export const COLORS = {
  standard: {
    standard: '#598691',
    darker: '#385A65',
    lighter: '#83C1CC'
  },
  purple: {
    standard: '#535699',
    darker: '#44477f',
    lighter: '#7a7eda'
  },
  red: {
    standard: '#a24346',
    darker: '#702e30',
    lighter: '#ea6065'
  },
  yellow: {
    standard: '#d6c123',
    darker: '#a3941a',
    lighter: '#fbe229'
  },
  green: {
    standard: '#5d8f50',
    darker: '#44693b',
    lighter: '#8cd778'
  }
}

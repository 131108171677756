<template>
  <div class="filters mb-5">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'filters'
}
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  .filters {
    margin: 0 -2rem;
    padding: 1rem 2rem;
    box-shadow: 0 0.15rem 0.5rem $primary inset;

    .select {
      width: 100%;
    }
  }
</style>

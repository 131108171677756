<template>
  <div class="transactions">
    <transaction-list />
  </div>
</template>

<script>
import TransactionList from '@/components/transactions/TransactionList.vue'

export default {
  name: 'Home',
  components: {
    TransactionList
  }
}
</script>

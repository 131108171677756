<template xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <div class="transaction-list mb-6">
    <subtitle class="is-flex is-justify-content-space-between">
      <span>{{ $t('transactions.title') }}</span>
      <button class="button is-small is-rounded" @click="showFilters = !showFilters">
        <span class="icon">
          <i class="fas fa-filter"></i>
        </span>
        <span>{{ $t('common.filter') }}</span>
      </button>
    </subtitle>

    <filters v-if="showFilters">
      <select-field
              :options="categoryOptions"
              :label="$t('transactions.list.filterByCategory')"
              name="categoryFilter"
              @change="filter"
              v-model="filters.categories"
              clearable
      />
    </filters>

    <template v-if="isLoading">
      <loader v-for="i in 3" :key="i" :height="6" />
    </template>

    <template v-else-if="transactions.length">
      <div class="mb-5" v-for="(transactionGroup, key) in transactions" :key="key">
        <div class="box monthly-total is-flex is-align-items-center is-justify-content-space-between" @click.prevent="toggleTransactionGroup(transactionGroup)">
          <div class="date has-text-white">
            <div class="month">{{ transactionGroup.month }}</div>
            <div class="year">{{ transactionGroup.year }}</div>
          </div>
          <div class="has-text-right total">
            <p class="subtitle has-text-white">
              {{ $t('common.total') }}
            </p>
            <p class="title has-text-white has-text-weight-bold">
              {{ $n(transactionGroup.total, 'currency') }}
            </p>
          </div>
        </div>

        <ul class="transaction-group show" v-if="transactionGroup.items.length" :ref="`transaction_group_${transactionGroup.month + transactionGroup.year}`">
          <li class="transaction" :ref="`transaction_box_${transaction._id}`" v-for="transaction in transactionGroup.items" :key="transaction._id" v-touch:swipe="toggleContextMenu(transaction._id)">
            <transaction-list-item
                    :transaction="transaction"
            />
          </li>
        </ul>
      </div>

      <div class="has-text-centered mt-5">
        <button :disabled="allTransactionsLoaded" @click="loadMore" class="button is-primary">
      <span class="icon">
        <i v-if="allTransactionsLoaded" class="fas fa-smile-wink"></i>
        <i v-else class="fa fa-arrow-down"></i>
      </span>
          <span v-if="allTransactionsLoaded">{{ $t('common.list.noNewItems') }}</span>
          <span v-else>{{ $t('common.list.loadMore') }}</span>
        </button>
      </div>
    </template>

    <empty-state v-else>
      {{ $t('transactions.emptyState') }}
    </empty-state>

    <call-to-button :link="`/${accountBook.slug}/transactions/new`" icon="fa fa-plus">
      {{ $t('transactions.newTitle') }}
    </call-to-button>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import SelectField from '../basic/SelectField'
import Subtitle from '../basic/Subtitle'
import CallToButton from '../basic/CallToButton'
import TransactionListItem from './TransactionListItem'
import EmptyState from '../basic/EmptyState'
import Loader from '../basic/Loader'
import Filters from '../basic/Filters'

export default {
  name: 'transaction-list',
  components: { Filters, Loader, EmptyState, TransactionListItem, CallToButton, Subtitle, SelectField },
  computed: {
    ...mapState({
      transactions: state => {
        const transactionGroups = []
        let currentMonthOfYear = null
        let currentGroup = null

        for (let i = 0; i < state.transactions.items.length; i++) {
          const transaction = state.transactions.items[i]
          const transactionDate = moment(transaction.date)
          const transactionMonth = transactionDate.format('MM')
          const transactionYear = transactionDate.format('YYYY')

          const monthOfYear = transactionYear + '-' + transactionMonth

          if (currentMonthOfYear === null || currentMonthOfYear !== monthOfYear) {
            currentMonthOfYear = monthOfYear

            const monthStatistics = state.statistics.amountByMonth.find(item => item._id === currentMonthOfYear)

            currentGroup = {
              month: transactionMonth,
              year: transactionYear,
              total: monthStatistics ? monthStatistics.totalAmount : 0,
              items: [],
              show: true
            }

            transactionGroups.push(currentGroup)
          }

          currentGroup.items.push(transaction)
        }

        return transactionGroups
      },
      allTransactionsLoaded: state => state.transactions.allLoaded,
      users: state => state.users.items,
      categories: state => state.categories.items,
      accountBook: state => state.accountBooks.current,
      isLoading: state => state.transactions.isLoading
    }),
    ...mapGetters([
      'categoryOptions'
    ])
  },
  data () {
    return {
      page: 1,
      filters: {
        categories: null
      },
      showFilters: false
    }
  },
  created () {
    this.$store.dispatch('statistics/amountByMonth')
    this.$store.dispatch('transactions/fetch')
    this.$store.dispatch('users/fetch')
    this.$store.dispatch('categories/fetch')
  },
  methods: {
    filter () {
      this.load()
      this.showFilters = false
    },
    load () {
      this.$store.dispatch('transactions/fetch', {
        filters: this.filters
      })
    },
    getUserName (userId) {
      const user = this.users.find(user => user._id === userId)

      if (!user) {
        return null
      }

      return user.name.match(/\b(\w)/g).join('')
    },
    getCategoryName (categoryId) {
      const category = this.categories.find(category => category._id === categoryId)

      if (!category) {
        return null
      }

      return category.name
    },
    loadMore () {
      this.$store.dispatch('transactions/loadMore', { page: ++this.page })
    },
    toggleContextMenu (transactionId) {
      return dir => {
        if (dir === 'left') {
          this.$refs[`transaction_box_${transactionId}`][0].classList.add('show-context-menu')
        }

        if (dir === 'right') {
          this.$refs[`transaction_box_${transactionId}`][0].classList.remove('show-context-menu')
        }
      }
    },
    async remove (_id) {
      if (confirm('Are you sure, you want to delete this transaction?')) {
        this.$store.dispatch('transactions/delete', _id)

        this.$store.commit('showAlertBar', { text: this.$t('transactions.deletedSuccessMessage'), type: 'success' })
      }
    },
    toggleTransactionGroup (transactionGroup) {
      this.$refs[`transaction_group_${transactionGroup.month + transactionGroup.year}`][0].classList.toggle('show')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  .transaction-list {
    .box {
      margin-bottom: 1rem;
      padding: 1rem;
    }
  }

  .transaction-group {
    max-height: 0;
    transition: max-height 0.3s ease;
    overflow: hidden;

    &.show {
      max-height: 300rem;
    }
  }

  .monthly-total {
    background: $primary;
    color: white;

    .date {
      text-align: center;
      font-weight: bold;

      .month {
        font-size: 2.5rem;
        line-height: 0.75;
      }
    }

    .total {
      .subtitle {
        line-height: 0.75;
      }
    }
  }

  .transaction {
    transition: transform 0.2s ease;

    p.subtitle {
      margin-bottom: 0.25em;
    }

    &.show-context-menu {
      transform: translateX(-6rem);

      .context-menu {
        right: -6rem;
      }
    }

    .context-menu {
      transition: right 0.2s ease;
      display: flex;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      justify-content: flex-end;
      padding: 0;

      .button {
        height: 100%;
        border-radius: 0;
        width: 3rem;

        &:last-child {
          border-radius: 0 6px 6px 0;
        }
      }
    }
  }
</style>
